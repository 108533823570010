<template>
<div class="w-100">
  <div class="card mb-4">
    <div class="card-header pb-0">
      <div class="row">
        <div class="col">
          <h6>DETALLE DEL PROCESO PRIMARIO</h6>
        </div>
      </div>
    </div>
    <div class="card-body px-4">
      <div class="row">
        <div class="col-lg-6 col-md-6 col-sm-12">
          <div class="text-h6 mb-1 text-center">
            <img style="width: 10rem" src="https://cdn-icons-png.flaticon.com/512/225/225369.png?w=826&t=st=1678550106~exp=1678550706~hmac=22289a5aa3def46a7df4eedd3bf0a4cf225ed7ecb4ab94cea99b82c19838b941" alt="Avatar" class="rounded-circle">
          </div>
          <div class="" v-if="primary_process">
            <p><strong>Titulo: </strong> {{primary_process.title}}</p>
            <p><strong>Descripcion: </strong> {{primary_process.description}}</p>
          </div>
        </div>
        <div class="col-lg-6 col-md-6 col-sm-12" v-if="primary_process">
          <div class="w-100 text-center">
            <label>CREAR PROCESO SECUNDARIO</label>
          </div>
          <label>Titulo</label>
          <input type="text" class="form-control" v-model="secondary_process.title">
          <label>¿Tiene archivos relacionados? <span class="text-info">
            <ul><li>SI: El usuario debera subir archivos.</li>
                <li>NO: El usuario no sube archivos y el sistema dara por completo el subproceso</li>
          </ul>
          </span> </label>
          <select name="has_file_related" id="has_file_related" class="form-control" v-model="secondary_process.has_file_related">
            <option value="">SELECCIONE</option>
            <option value="SI">SI</option>
            <option value="NO">NO</option>
          </select>
          <label>Descripcion</label>
          <textarea type="text" class="form-control" v-model="secondary_process.description" rows="5"></textarea>
          <button class="btn bg-gradient-success w-100 mt-4" @click="createPrimaryProcess">CREAR PROCESO SECUNDARIO</button>
        </div>
      </div>
    </div>
  </div>
  <div class="card mb-4">
    <div class="card-header pb-0">
      <div class="row">
        <div class="col">
          <h6>LISTADO DE PROCESOS SECUNDARIOS</h6>
        </div>
      </div>
    </div>
    <div class="card-body px-4">
      <div class="row">
        <div class="table-responsive">
          <table class="table table-bordered border table-striped">
            <thead>
            <tr>
              <th scope="col">Título</th>
              <th scope="col">Descripción</th>
              <th scope="col">Archivo relacionado</th>
              <th scope="col">Opciones</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="item in array_secondary_process" :key="item.name">
              <td>{{ item.title }}</td>
              <td>
                <div class="limited-width_50">
                  {{ item.description }}
                </div>
              </td>
              <td>{{ item.has_file_related ? 'SI' : 'NO' }}</td>
              <td>
                <button class="btn btn-outline-danger btn-sm mb-0" @click="deleteItem(item)">
                  <i class="fas fa-trash"></i>
                </button>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</div>

</template>
<script>
import {get, post} from "@/utils/httpRequest";
import Swal from "sweetalert2";

export default {
  data: () => ({
    process_id: null,
    primary_process: null,
    array_secondary_process: [],
    secondary_process: {
      title: '',
      description: '',
      has_file_related: '',
      main_process: null
    }
  }),
  mounted() {
    this.process_id = this.$route.query.id
    this.getPrimaryProcessData()
    this.getSecondaryProcessData()
  },
  methods: {
    async getPrimaryProcessData(){
      const {data} = await get(`main-process/find-one?id=${this.process_id}`)
      this.primary_process = data
    },
    async getSecondaryProcessData(){
      const {data} = await get(`secondary-process/find-by-main-process?id=${this.process_id}`)
      this.array_secondary_process = data
    },
    async createPrimaryProcess(){
      if(this.secondary_process.title && this.secondary_process.description && this.secondary_process.has_file_related !== ''){
        await post('secondary-process/create',
            {
              title: this.secondary_process.title,
              description: this.secondary_process.description,
              has_file_related: this.secondary_process.has_file_related === 'SI',
              main_process: this.process_id
            })
        await Swal.fire('Informacion creada correctamente', '', 'success')
        location.reload()
      }else{
        await Swal.fire('Todos los campos son requeridos', '', 'error')
      }
    },
    async deleteItem(item){
      await Swal.fire({
        title: 'Estas seguro?',
        text: "No podras revertir esto!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#82d616',
        cancelButtonColor: '#ea0606',
        confirmButtonText: 'SI',
        cancelButtonText: 'NO'
      }).then(async (result) => {
        if (result.isConfirmed) {
          await post(`secondary-process/delete`, {id: item._id})
          await this.getSecondaryProcessData()
          await Swal.fire('Informacion eliminada correctamente', '', 'success')
        }
      })
    }
  }
}
</script>
<style scoped>

</style>
